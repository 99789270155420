import * as React from "react"
import Layout from "../components/layout.js"
import Image from "../components/image"

const FormerlyHumanPage = () => {
  return (
    <Layout title="Lycanthrope: Make/Unmake">
        <h2>Scenario</h2>
        <blockquote>I shall never lie among the honored dead! Bury my body in the earth, plant my grave with yarrow to protect me from the devil. In this way let me be immortal.</blockquote>
        <center><Image src="makeunmake.png" alt="Drawing" /></center>
        <p>&nbsp;</p>
        <center><Image src="makeunmake-crop2.png" alt="Drawing" /></center>
        <center><Image src="makeunmake-crop1.png" alt="Drawing" /></center>
    </Layout>
  )
}

export default FormerlyHumanPage